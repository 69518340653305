<template>
    <v-container>
        <v-row>
            <v-col cols="6" offset="3">
                <v-card>

                    <v-form @submit.prevent="search">
                        <v-card-text>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                            :label="$t('search_by_name')"
                                            type="text"
                                            v-model="searchName"
                                            append-icon="mdi-magnify"
                                            @click:append="search"
                                    />
                                </v-col>
                            </v-row>
                        </v-card-text>

                        <v-card-actions>
                            {{$t('only_not_published')}}
                        </v-card-actions>
                    </v-form>

                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="2"
                   v-for="image in images"
                   :key="image.id"
            >
                <v-card>
                    <v-img :src="'thumbnails/' + image.thumbnail"
                           aspect-ratio="1"
                    ></v-img>

                    <v-card-actions>
                        <span class="caption font-italic font-weight-light text-truncate">
                            {{ image.image_file }}
                        </span>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-form @submit.prevent="save">
            <v-row v-if="showEditForm">

                <v-col cols="4">
                    <v-text-field :label="$t('brand')" v-model="imageDetails.brand"/>
                    <v-text-field :label="$t('variety')" v-model="imageDetails.variety"/>
                    <v-text-field :label="$t('type')" v-model="imageDetails.name"/>
                    <v-text-field :label="$t('cultivar')" v-model="imageDetails.cultivar"/>
                </v-col>

                <v-col cols="4">
                    <v-text-field :label="$t('code')" v-model="imageDetails.code"/>

                    <v-autocomplete
                            :label="$t('document_group')"
                            :items="documentGroups"
                            :value="imageDetails.document_group"
                            v-model="imageDetails.document_group"
                    >
                    </v-autocomplete>

                    <v-autocomplete
                            :label="$t('published')"
                            :items="getPublished"
                            :value="imageDetails.published"
                            v-model="imageDetails.published"
                    >
                    </v-autocomplete>

                    <v-autocomplete
                            :label="$t('category')"
                            :items="getCategories"
                            :value="imageDetails.category"
                            v-model="imageDetails.category"
                    >
                    </v-autocomplete>
                </v-col>

                <v-col cols="4">
                    <v-text-field :label="$t('photography')" v-model="imageDetails.photography"/>
                    <v-text-field :label="$t('keywords')" v-model="imageDetails.keywords"/>

                    <v-autocomplete
                            :label="$t('shot')"
                            :items="getShots"
                            :value="imageDetails.shot"
                            v-model="imageDetails.shot"
                    >
                    </v-autocomplete>

                    <v-btn type="submit" color="info">
                        <v-icon dark left>mdi-send</v-icon>
                        {{ $t('apply_to_selection') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>

        <v-snackbar id="snackbar" :color=snackbarColor :top=snackbarTop v-model="snackbar">
            {{ snackbarMessage }}

            <v-btn text @click="snackbar=false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import {mapState, mapGetters} from 'vuex'
    import imageService from '@/services/ImageService.js'

    export default {
        name: "UploadEdit",

        computed: {
            ...mapState(['isAdmin']),
            ...mapGetters(['getDocumentGroups', 'getDocumentGroupsBranding', 'getShots', 'getPublished', 'getCategories']),
        },

        created() {
            // Route guard
            if (!this.isAdmin) {
                this.$store.dispatch('logUserOut')
                this.$router.push('/login')
            }

            this.init()
        },

        data() {
            return {
                searchName: '',
                images: [],
                showEditForm: false,

                imageDetails: {
                    brand: '',
                    variety: '',
                    name: '',
                    cultivar: '',

                    code: '',
                    document_group: '',
                    published: 'Yes',
                    category: '',

                    photography: '',
                    keywords: '',
                    shot: '',
                },
                documentGroups: [],

                snackbar: false,
                snackbarTop: true,
                snackbarColor: '',
                snackbarMessage: '',
            }
        },

        methods: {
            init() {
                // Clear all
                this.searchName = ''
                this.images = null
                this.showEditForm = false

                this.imageDetails.brand = ''
                this.imageDetails.variety = ''
                this.imageDetails.name = ''
                this.imageDetails.cultivar = ''

                this.imageDetails.code = ''
                this.imageDetails.document_group = ''
                this.imageDetails.published = 'Yes'
                this.imageDetails.category = ''

                this.imageDetails.photography = ''
                this.imageDetails.keywords = ''
                this.imageDetails.shot = ''

                // Merge both documentgroups
                this.documentGroups = [...this.getDocumentGroups, ...this.getDocumentGroupsBranding]
            },

            search() {
                imageService.uneditedFind(
                    {
                        searchName: this.searchName,
                    }
                )
                    .then(response => {
                        this.images = response.data
                        this.showEditForm = true
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('no_results')
                    })
            },

            save() {
                imageService.uneditedUpdate(
                    {
                        imageDetails: this.imageDetails,
                        images: this.images,
                    }
                )
                    .then(() => {
                        this.snackbar = true
                        this.snackbarColor = "success"
                        this.snackbarMessage = this.$t('data_saved')

                        this.init()
                    })
                    .catch(() => {
                        this.snackbar = true
                        this.snackbarColor = "error"
                        this.snackbarMessage = this.$t('invalid_data')
                    })
            },
        },
    }
</script>

<style scoped>

</style>
